<template>
  <v-card
    v-if="company"
    :height="`${($vuetify.breakpoint.height - 230).toString() + 'px'}`"
    flat
    class="mt-12"
  >
    <v-form>
      <v-container py-6>
        <v-row wrap>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              label="Professional (disabled)"
              disabled
              outlined
              dense
              v-text="$t('members|account_type') + ':' + ' ' + company.subscriptionType"
            />
          </v-col>
          <v-col
            xs="12"
            md="4"
          >
            <v-text-field
              v-model="companyData.companyName"
              :placeholder="companyData.companyName"
              :label="$t('clients|company_name')"
              class="purple-input"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="4"
          >
            <v-text-field
              v-model="companyData.email"
              :label="$t('company|email')"
              class="purple-input"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="4"
          >
            <v-text-field
              v-model="companyData.phone"
              :label="$t('profile|phone')"
              class="purple-input"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="4"
          >
            <v-text-field
              v-model="companyData.fax"
              :label="$t('profile|fax')"
              class="purple-input"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="4"
          >
            <v-text-field
              v-model="companyData.registrationNumber"
              :label="$t('profile|company_registration_number')"
              class="purple-input"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="12"
          >
            <v-text-field
              v-model="companyData.address"
              :label="$t('clients|address')"
              class="purple-input"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="4"
          >
            <v-text-field
              v-model="companyData.city"
              :label="$t('clients|city')"
              class="purple-input"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="4"
          >
            <v-autocomplete
              v-model="companyData.nationality"
              :items="CountryList"
              item-text="en_short_name"
              item-value="alpha_2_code"
              :label="$t('profile|country')"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="4"
          >
            <v-text-field
              v-model="companyData.postal_code"
              class="purple-input"
              :label="$t('clients|postal_code')"
              type="number"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="6"
          >
            <v-text-field
              v-model="companyData.representativeName"
              :label="$t('company|representative_name')"
              class="purple-input"
              outlined
              dense
            />
          </v-col>
          <v-col
            xs="12"
            md="6"
          >
            <v-text-field
              v-model="companyData.representativeEmail"
              :label="$t('company|representative_email')"
              class="purple-input"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row
          justify="end"
        >
          <v-btn
            v-if="checkPermission()"
            class="font-weight-light mr-3 mt-2"
            color="primary"
            rounded
            small
            @click="updateCompanyData"
          >
            {{ $t('profile|update_profile') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CountryList from '@/enums/CountryList.json'

export default {
  data () {
    return {
      companyData: null,
      CountryList,
      premissionRule: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  },
  watch: {
    company: {
      handler (value) {
        this.companyData = value.companyData || {}
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      updateCompany: 'company/updateCompany',
      deleteTheCompany: 'company/deleteTheCompany'
    }),
    checkPermission () {
      const foundAdminUserRole = this.company.userRoles.admin.some(ur => ur === this.account._id)
      const foundAdminOwnerUserRole = this.company.userRoles.adminOwner.some(ur => ur === this.account._id)
      if (foundAdminUserRole) {
        return foundAdminUserRole
      } else if (foundAdminOwnerUserRole) {
        return foundAdminOwnerUserRole
      } else {
        return false
      }
    },
    async updateCompanyData () {
      const _id = this.company._id
      const payload = {
        companyData: this.companyData
      }
      try {
        this.updateCompany({ _id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    }
  }
}
</script>

<style>
.v-text-field-bussines-card > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.v-offset {
  margin: 0 auto;
  max-width: calc(100% - 32px);
  position: relative;
}
.document-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.document-list-container {
  flex-grow: 1;
  overflow-y: auto;
}
</style>
